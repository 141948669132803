// this code also working dont remove this code.

import {
  Box,
  Grid,
  Container,
  Typography,
  Chip,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
  CircularProgress ,
  Skeleton,
  Pagination
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { useEffect, useState } from 'react'
import Page from '../components/Page'
import { AppVerifiedUsers } from '../components/_dashboard/app'
import { services } from '../services'
import './dashboardStyle.css'
import DashChartData from 'src/components/charts/DashChartData'
import DemographicsChart from 'src/components/charts/DemographicsChart'
import TaskCompletionChart from 'src/components/charts/TaskCompletionChart'

export default function DashboardApp () {
  const [data, setData] = useState({
    totalKlinkersPoints: 0,
    totalTouches: 0,
    totalKlinkers: 0,
    dailyActiveKlinkers: 0,
    activeKlinkers: 0,
    totalVerifiedUser: 0,
    totalCompletedTasks: 0,
    totalTasksCompleted: 0,
    InactiveUsers: 0,
    totalReferrals: 0
  })

  const [chartData, setChartData] = useState({
    totalKlinkersPoints: 0,
    totalTouches: 0,
    totalKlinkers: 0,
    dailyActiveKlinkers: 0,
    activeKlinkers: 0,
    totalVerifiedUser: 0,
    totalTasksCompleted: 0,
    InactiveUsers: 0,
    totalReferrals: 0
  })

  const [taskCompletionCounts, setTaskCompletionCounts] = useState([])
  const [startDate1, setStartDate1] = useState(null)
  const [endDate1, setEndDate1] = useState(null)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('taskName')
  const [filterText, setFilterText] = useState('')
  const [isLoading, setIsLoading] = useState(true)

  const [partnerStats, setPartnerStats] = useState([])
  const [partnerStartDate, setPartnerStartDate] = useState(null)
  const [partnerEndDate, setPartnerEndDate] = useState(null)
  const [partnerFilterText, setPartnerFilterText] = useState('')
  const [partnerPage, setPartnerPage] = useState(1)
  const [partnerRowsPerPage] = useState(10)
  const [totalPartnerPages, setTotalPartnerPages] = useState(0)
  const [isPartnerDataAvailable, setIsPartnerDataAvailable] = useState(true)

  const fetchData = async (start, end) => {
    setIsLoading(true)
    try {
      let userStatsUrl = `${process.env.REACT_APP_BASE_URL}/user/userStats`
      let userChartStatsUrl = `${process.env.REACT_APP_BASE_URL}/user/userChartStats`

      // let taskCompletionUrl = `${process.env.REACT_APP_BASE_URL}/task/task-completion-counts`

      if (start && end) {
        userStatsUrl += `?startDate=${start}&endDate=${end}`
        userChartStatsUrl += `?startDate=${start}&endDate=${end}`
        // taskCompletionUrl += `?startDate=${start}&endDate=${end}`
      }

      const userStatsResponse = await services.Get(userStatsUrl)
      if (userStatsResponse.status === 200) {
        const statistics = userStatsResponse.data
        setData(statistics)
      }

      const userChartStatsResponse = await services.Get(userChartStatsUrl)
      if (userChartStatsResponse.status === 200) {
        const chartStatistics = userChartStatsResponse.data

        setChartData(chartStatistics)
      }

      // const taskCompletionResponse = await services.Get(taskCompletionUrl)
      // if (taskCompletionResponse.status === 200) {
      //   setTaskCompletionCounts(taskCompletionResponse.data.data)
      // }
    } catch (error) {
      console.error('Error fetching data:', error)
    } finally {
      setIsLoading(false)
    }
  }
  const fetchUserTaskData = async (start, end) => {
    setIsLoading(true)
    try {
      let taskCompletionUrl = `${process.env.REACT_APP_BASE_URL}/task/task-completion-counts`

      if (start && end) {
        taskCompletionUrl += `?startDate=${start}&endDate=${end}`
      }
      const taskCompletionResponse = await services.Get(taskCompletionUrl)
      if (taskCompletionResponse.status === 200) {
        setTaskCompletionCounts(taskCompletionResponse.data.data)
      }
    } catch (error) {
      console.error('Error fetching user task data:', error)
      setTaskCompletionCounts([]) // Set to empty array on error
    } finally {
      setIsLoading(false)
    }
  }

  const fetchPartnerStats = async (start, end, page = 1, filter = '') => {
    setIsLoading(true);
    try {
      let url = `${process.env.REACT_APP_BASE_URL}/user/patnerStats?page=${page}&limit=${partnerRowsPerPage}`;
      
      if (start && end) {
        // Convert start and end to ISO format without time to avoid timezone issues
        const adjustedStart = new Date(start);
        adjustedStart.setHours(0, 0, 0, 0); // Set start date to the beginning of the day
        
        const adjustedEnd = new Date(end);
        adjustedEnd.setHours(23, 59, 59, 999); // Set end date to the end of the day
  
        url += `&startDate=${adjustedStart.toISOString()}&endDate=${adjustedEnd.toISOString()}`;
      }
      
      if (filter) {
        url += `&filter=${encodeURIComponent(filter)}`;
      }
      
      const response = await services.Get(url);
      
      if (response.status === 200) {
        setPartnerStats(response.data.sourceStats);
        setTotalPartnerPages(response.data.totalPages);
        setIsPartnerDataAvailable(response.data.sourceStats.length > 0);
      }
    } catch (error) {
      console.error('Error fetching partner stats:', error);
      setIsPartnerDataAvailable(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFilter = () => {
    if (startDate && endDate) {
      fetchData(startDate.toISOString(), endDate.toISOString())
    } else if (startDate) {
      fetchData(startDate.toISOString(), startDate.toISOString())
    } else if (endDate) {
      fetchData(endDate.toISOString(), endDate.toISOString())
    } else {
      fetchData()
    }
  }

  const handleFilter1 = () => {
    if (startDate1 && endDate1) {
      fetchUserTaskData(startDate1.toISOString(), endDate1.toISOString())
    } else if (startDate1) {
      fetchUserTaskData(startDate1.toISOString(), startDate1.toISOString())
    } else if (endDate1) {
      fetchUserTaskData(endDate1.toISOString(), endDate1.toISOString())
    } else {
      fetchUserTaskData()
    }
  }

  useEffect(() => {
    fetchData()
    fetchUserTaskData()
    fetchPartnerStats()
  }, [])

  useEffect(() => {
    fetchPartnerStats(
      partnerStartDate?.toISOString(),
      partnerEndDate?.toISOString(),
      partnerPage,
      partnerFilterText
    )
  }, [partnerPage, partnerFilterText, partnerStartDate, partnerEndDate])

  const formatNumber = number => {
    return new Intl.NumberFormat('en-US').format(number)
  }

  const handleRequestSort = property => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const filteredTasks = taskCompletionCounts.filter(
    task =>
      task.taskName.toLowerCase().includes(filterText.toLowerCase()) ||
      task.category.toLowerCase().includes(filterText.toLowerCase())
  )

  const sortedTasks = filteredTasks.sort((a, b) => {
    if (orderBy === 'taskName') {
      return order === 'asc'
        ? a.taskName.localeCompare(b.taskName)
        : b.taskName.localeCompare(a.taskName)
    } else if (orderBy === 'category') {
      return order === 'asc'
        ? a.category.localeCompare(b.category)
        : b.category.localeCompare(a.category)
    }
    return 0
  })

  const filteredPartnerStats = partnerStats.filter(partner =>
    partner.partnerName.toLowerCase().includes(partnerFilterText.toLowerCase())
  )


  const PartnerActivityTable = () => (
    <TableContainer
      component={Paper}
      sx={{
        mt: 4,
        background: 'black',
        boxShadow: '0px 4px 8px rgba(255, 255, 255, 0.3)',
        height: '400px',
        overflow: 'auto'
      }}
    >
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell sx={{ color: '#674eff', background: 'rgb(33, 33, 33)' }}>
              Patner name
            </TableCell>
            <TableCell sx={{ color: '#674eff', background: 'rgb(33, 33, 33)' }}>
              Launched bot
            </TableCell>
            <TableCell sx={{ color: '#674eff', background: 'rgb(33, 33, 33)' }}>
              Tapped user
            </TableCell>
            <TableCell sx={{ color: '#674eff', background: 'rgb(33, 33, 33)' }}>
              Daily Active users
            </TableCell>
            <TableCell sx={{ color: '#674eff', background: 'rgb(33, 33, 33)' }}>
              Claimed tickets
            </TableCell>
            <TableCell sx={{ color: '#674eff', background: 'rgb(33, 33, 33)' }}>
              Connected klink account
            </TableCell>
            <TableCell sx={{ color: '#674eff', background: 'rgb(33, 33, 33)' }}>
              Churn (not active in 10 days)
            </TableCell>
            <TableCell sx={{ color: '#674eff', background: 'rgb(33, 33, 33)' }}>
              Tasks Completed
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading ? (
            <TableRow>
              <TableCell colSpan={8} sx={{ textAlign: 'center', height: '400px', color: 'white' }}>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                  <CircularProgress color="primary" />
                </Box>
              </TableCell>
            </TableRow>
          ) : isPartnerDataAvailable ? (
            partnerStats.map(partner => (
              <TableRow key={partner.source}>
                <TableCell>{partner.partnerName}</TableCell>
                <TableCell>{formatNumber(partner.totalKlinkers)}</TableCell>
                <TableCell>{formatNumber(partner.usersWithTouches)}</TableCell>
                <TableCell>{formatNumber(partner.dailyActiveKlinkers)}</TableCell>
                <TableCell>{formatNumber(partner.totalTickets)}</TableCell>
                <TableCell>{formatNumber(partner.totalVerifiedUser)}</TableCell>
                <TableCell>{formatNumber(partner.InactiveUsers)}</TableCell>
                <TableCell>{formatNumber(partner.totalTasksCompleted)}</TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell
                colSpan={8}
                sx={{
                  textAlign: 'center',
                  height: '400px',
                  color: 'white',
                  verticalAlign: 'middle'
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%'
                  }}
                >
                  <Typography variant='h6'>
                    Data not found for the selected date range
                  </Typography>
                </Box>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )

  return (
    <Page title='Dashboard | Community Stats'>
      <Container
        maxWidth='xl'
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          flexDirection: 'column'
        }}
      >
        <Box sx={{ pb: 5, textAlign: 'center' }}>
          <Typography variant='h4' color='white'>
            Welcome To Klink Bot Admin Panel
          </Typography>
        </Box>

        {/* General Statistics */}
        <Grid container spacing={3} sx={{ mb: 4 }} justifyContent='flex-start'>
          <Grid item xs={12} sm={6} md={3}>
            {isLoading ? (
              <Skeleton
                variant='rectangular'
                animation='wave'
                sx={{ borderRadius: '16px', height: 150 }}
              />
            ) : (
              <AppVerifiedUsers
                count={formatNumber(data.totalKlinkersPoints)}
                name='Total Klinkers Points'
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            {isLoading ? (
              <Skeleton
                variant='rectangular'
                animation='wave'
                className='skeleton'
                sx={{ borderRadius: '16px', height: 150 }}
              />
            ) : (
              <AppVerifiedUsers
                name='Total Touches'
                count={formatNumber(data.totalTouches)}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            {isLoading ? (
              <Skeleton
                variant='rectangular'
                animation='wave'
                sx={{ borderRadius: '16px', height: 150 }}
              />
            ) : (
              <AppVerifiedUsers
                name='Total Klinkers'
                count={formatNumber(data.totalKlinkers)}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            {isLoading ? (
              <Skeleton
                variant='rectangular'
                animation='wave'
                sx={{ borderRadius: '16px', height: 150 }}
              />
            ) : (
              <AppVerifiedUsers
                name='Daily Active Klinkers'
                count={formatNumber(data.dailyActiveKlinkers)}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            {isLoading ? (
              <Skeleton
                variant='rectangular'
                animation='wave'
                sx={{ borderRadius: '16px', height: 150 }}
              />
            ) : (
              <AppVerifiedUsers
                name='Active Klinkers'
                count={formatNumber(data.activeKlinkers)}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            {isLoading ? (
              <Skeleton
                variant='rectangular'
                animation='wave'
                sx={{ borderRadius: '16px', height: 150 }}
              />
            ) : (
              <AppVerifiedUsers
                name='Klink Connected Users'
                count={formatNumber(data.totalVerifiedUser)}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            {isLoading ? (
              <Skeleton
                variant='rectangular'
                animation='wave'
                sx={{ borderRadius: '16px', height: 150 }}
              />
            ) : (
              <AppVerifiedUsers
                name='Total Completed Task'
                count={formatNumber(data.totalTasksCompleted)}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            {isLoading ? (
              <Skeleton
                variant='rectangular'
                animation='wave'
                sx={{ borderRadius: '16px', height: 150 }}
              />
            ) : (
              <AppVerifiedUsers
                name='Inactive Churned'
                count={formatNumber(data.InactiveUsers)}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            {isLoading ? (
              <Skeleton
                variant='rectangular'
                animation='wave'
                sx={{ borderRadius: '16px', height: 150 }}
              />
            ) : (
              <AppVerifiedUsers
                name='Referals'
                count={formatNumber(data.totalReferrals)}
              />
            )}
          </Grid>
        </Grid>

        {/* Date Range Picker */}
        <Grid
          container
          sx={{ mb: 4 }}
          justifyContent='flex-start'
          alignItems='center'
        >
          <Grid item sx={{ width: 200, mr: 1 }}>
            <DatePicker
              label='Start Date'
              value={startDate1}
              onChange={newValue => setStartDate1(newValue)}
              renderInput={params => <TextField {...params} fullWidth />}
            />
          </Grid>
          <Grid item sx={{ width: 200, mx: 1 }}>
            <DatePicker
              label='End Date'
              value={endDate1}
              onChange={newValue => setEndDate1(newValue)}
              renderInput={params => <TextField {...params} fullWidth />}
            />
          </Grid>
          <Grid item sx={{ ml: 1 }}>
            <Button
              variant='contained'
              color='primary'
              onClick={handleFilter1}
              fullWidth
            >
              Filter
            </Button>
          </Grid>
        </Grid>

        <Box sx={{ width: '100%', height: '500px', position: 'relative' }}>
          <Typography
            variant='h4'
            color='white'
            sx={{ textAlign: 'center', mb: 2 }}
          >
            User's Per Task
          </Typography>

          {isLoading ? (
            <Skeleton variant='rectangular' width='100%' />
          ) : taskCompletionCounts.length > 0 ? (
            <TaskCompletionChart
              key={JSON.stringify(taskCompletionCounts)}
              taskCompletionCounts={taskCompletionCounts}
            />
          ) : (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '400px', // or any height that fits your needs
                width: '100%',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)'
              }}
            >
              <Typography variant='h6' color='white' textAlign='center'>
                No task completion data available for the selected date range
              </Typography>
            </Box>
          )}
        </Box>

        {/* Partners Activity Section */}
        <Box sx={{ mt: 6, mb: 3, textAlign: 'center' }}>
          <Typography variant='h5' color='white'>
            Partners Activity
          </Typography>
        </Box>

        {/* Date Range Picker for Partners Activity */}
        <Grid
          container
          sx={{ mb: 4 }}
          justifyContent='flex-start'
          alignItems='center'
        >
          <Grid item sx={{ width: 200, mr: 1 }}>
            <DatePicker
              label='Start Date'
              value={partnerStartDate}
              onChange={newValue => setPartnerStartDate(newValue)}
              renderInput={params => <TextField {...params} fullWidth />}
            />
          </Grid>
          <Grid item sx={{ width: 200, mx: 1 }}>
            <DatePicker
              label='End Date'
              value={partnerEndDate}
              onChange={newValue => setPartnerEndDate(newValue)}
              renderInput={params => <TextField {...params} fullWidth />}
            />
          </Grid>
          <Grid item sx={{ ml: 1, width: 200 }}>
            <TextField
              label='Filter by patner Name'
              value={partnerFilterText}
              onChange={e => setPartnerFilterText(e.target.value)}
              fullWidth
            />
          </Grid>
        </Grid>

        {/* Partners Activity Table */}
        <PartnerActivityTable />

        {/* Pagination for Partners Activity */}
        {isPartnerDataAvailable && (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            <Pagination
              count={totalPartnerPages}
              page={partnerPage}
              onChange={(event, value) => setPartnerPage(value)}
              color='primary'
            />
          </Box>
        )}
      </Container>
    </Page>
  )
}
